<template>
    <div class="main-wrapper">
        <div class="certificate-item">
            <ViewCertificateModule :obj="certificateForm" id="ViewCertificateModule" ref="ViewCertificateModule"/>
        </div>
        <div class="btn-footer">
            <el-button size="medium" class="btn" @click="returnList">
                <i class="iconfont">&#xe72e;</i>
                <span style="margin-top:8px">返回</span>
            </el-button>
            <el-button size="medium" class="btn-blue" @click="printCertificateModule">
                <i class="iconfont">&#xe621;</i>
                <span style="margin-top:8px">打印</span>
            </el-button>
            <el-button size="medium" class="btn-g" @click="next()">
                <span>下一位</span>
            </el-button>
        </div>
    </div>
</template>

<script>
    import {studentCertificate,nextCertificate} from '@/utils/apis'
    import ViewCertificateModule from '../../../components/teacher/certificateContent/ViewCertificateModule.vue'
    import Print from '@/utils/vue-print-nb/src/printarea.js'
    export default {
        name: "ViewCertificate",
        components: {
            ViewCertificateModule,
        },
        data(){
            return{
                certificateForm:{},
                role:'',//角色
            }
        },
        created() {
            this.role = localStorage.getItem('role');
        },
        mounted() {
            let id = this.$route.query.id;
            if(id){
                this.viewCertificate(id);
            }
        },
        methods:{
            //返回
            returnList(){
                if(this.role === '2'){
                    this.$router.push({
                        path: '/school/certificate/getCertificateList',
                        query:{
                            id:this.$route.query.id,
                            exam_name:this.$route.query.exam_name,
                            exam_id:this.$route.query.exam_id,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/evaluationCenter/getCertificateList',
                        query:{
                            id:this.$route.query.id,
                            exam_name:this.$route.query.exam_name,
                            exam_id:this.$route.query.exam_id,
                        }
                    })
                }

            },
            //下一位
            next(){
                let params = {
                    certificate_id:this.$route.query.id,
                    exam_id:this.$route.query.exam_id
                }
                nextCertificate(params).then((res) => {
                    this.certificateForm.certificate_id = res.data.id
                    this.$message.success(res.msg);
                    if(this.role == 2){
                        this.$router.push({
                            path:'/school/certificate/viewCertificate',
                            query:{
                                id:res.data.id,
                                exam_id:this.$route.query.exam_id,
                                exam_name:this.$route.query.exam_name,
                            }
                        })
                    } else {
                        this.$router.push({
                            path:'/evaluationCenter/viewCertificate',
                            query:{
                                id:res.data.id,
                                exam_id:this.$route.query.exam_id,
                                exam_name:this.$route.query.exam_name,
                            }
                        })
                    }
                    this.certificateForm.certificate_id = res.data.id
                    this.certificateForm.certificate_name = res.data.certificate_name
                    this.certificateForm.english_name = res.data.certificate_ename;
                    this.certificateForm.evaluate_name = res.data.appraise_name;
                    this.certificateForm.evaluate_english_name = res.data.appraise_ename;
                    this.certificateForm.information = res.data.appraise_info_site;
                    this.certificateForm.organization = res.data.certificate_ename;
                    this.certificateForm.occupation_name = res.data.work_name;
                    this.certificateForm.job = res.data.work_type;
                    this.certificateForm.skill_level = res.data.work_class;
                    this.certificateForm.certificate_num = res.data.certificate_num;
                    this.certificateForm.id_num = res.data.id_num;
                    this.certificateForm.f_organization = res.data.branch_organiza_name;
                    this.certificateForm.dateTimeStamp = res.data.certificate_time * 1000;
                    this.certificateForm.model = res.data.certificate_template;
                    this.certificateForm.account = res.data.username;
                    this.certificateForm.id_type = res.data.certificate_type;
                    this.certificateForm.id_tyavatarpe = res.data.certificate_avatar;
                    this.certificateForm.qr_code = res.data.qr_code;
                    this.timeFormat(res.data.certificate_time * 1000)
                    // if(res.msg==='已经是最后一位学生！'){
                    //     // 最后一个的提示
                    //     this.$message.warning(res.msg);
                    // }else{
                    //     // 其他情况
                    //     this.$message.success(res.msg);
                    // }
                }).catch((err) => {
                    console.log(err);
                });
            },
            viewCertificate(id) {
               let params = {
                   certificate_id:id
               }
                studentCertificate(params).then((res) => {
                    this.certificateForm = res.data;
                    this.certificateForm.certificate_name = res.data.certificate_name
                    this.certificateForm.english_name = res.data.certificate_ename;
                    this.certificateForm.evaluate_name = res.data.appraise_name;
                    this.certificateForm.evaluate_english_name = res.data.appraise_ename;
                    this.certificateForm.information = res.data.appraise_info_site;
                    this.certificateForm.organization = res.data.certificate_ename;
                    this.certificateForm.occupation_name = res.data.work_name;
                    this.certificateForm.job = res.data.work_type;
                    this.certificateForm.skill_level = res.data.work_class;
                    this.certificateForm.certificate_num = res.data.certificate_num;
                    this.certificateForm.id_num = res.data.id_num;
                    this.certificateForm.f_organization = res.data.branch_organiza_name;
                    this.certificateForm.dateTimeStamp = res.data.certificate_time * 1000;
                    this.certificateForm.model = res.data.certificate_template;
                    this.certificateForm.account = res.data.username;
                    this.certificateForm.id_type = res.data.certificate_type;
                    this.certificateForm.id_tyavatarpe = res.data.certificate_avatar;
                    this.certificateForm.qr_code = res.data.qr_code;
                    this.timeFormat(res.data.certificate_time * 1000)
                }).catch((err) => {
                    console.log(err);
                });
            },
            timeFormat(dateTimeStamp){
                // 日期格式
                let time = new Date(dateTimeStamp)
                let y = time.getFullYear();
                let m = time.getMonth()>8?time.getMonth()+1:'0'+(time.getMonth()+1);
                let d = time.getDate()>9?time.getDate():'0'+time.getDate();
                this.certificateForm.date_time = y+'年'+m+'月'+d+'日';
                // this.certificateForm.dateTimeStamp = val;
                let ye = time.toDateString().split(" ")[3];
                let me = time.toDateString().split(" ")[1];
                let de = parseInt(time.toDateString().split(" ")[2])
                this.certificateForm.date_etime = ''+ me +' '+ de + ',' + ye;
            },
            printCertificateModule(){
                let that = this
                new Print({
                    ids: "#ViewCertificateModule", // * 局部打印必传入id
                    endCallback() {
                        // 调用打印之后的回调事件
                        setTimeout(() => {
                            that.$router.go(0)
                            console.log('success');
                        }, 100);
                    }
                });

            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: auto;
        justify-content: center;
        box-sizing: border-box;
        .btn-footer {
            display: flex;
            flex-direction: column;
            align-self: center;
            padding-left: 20px;
            .btn{
                width: 64px;
                height: 64px;
                background: #fff;
                border: 1px solid #D2D2D2;
                color:#A0A0A0;
                border-radius: 4px;
                margin-left: 10px;
                span{
                    display: flex;
                    flex-direction: column;
                    i{
                        height: 16px;
                        line-height: 0;
                        font-size: 20px;
                        color: #A0A0A0;
                    }
                }
            }
            .btn-blue{
                width: 64px;
                height: 64px;
                background: #E4ECFF;
                border: 1px solid #2461EF;
                color: #2461EF;
                border-radius: 4px;
                margin: 30px 10px;
                span{
                    display: flex;
                    flex-direction: column;
                    i{
                        height: 16px;
                        line-height: 0;
                        font-size: 20px;
                    }
                }
            }
            .btn-g{
                width: 64px;
                height: 64px;
                background: #E7F6EF;
                border: 1px solid #2DC079;
                color: #2DC079;
                border-radius: 4px;
                span{
                    margin-left: -8px;
                }
            }
        }
        .certificate-item{
            display: flex;
            align-items: center;
        }
    }
</style>